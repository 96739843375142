import React from 'react'
import tw from 'twin.macro'
import { css } from '@emotion/react'
import { graphql, Link } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { GatsbyImage } from 'gatsby-plugin-image'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import {
  ButtonStyle,
  CopySMStyle,
  CopyStyle,
  HeaderLGStyle,
  SubheadSMStyle,
  SubheadStyle,
  BlogSubhead,
  BlogCopy,
  BlogImage,
  BlogImageCaption,
  LinkButton
} from '../components/UI'
import { Disclosure } from '@headlessui/react'
import CallToAction from '../components/CallToAction'
import slugify from 'slugify'
import arrow from '../images/icons/footer-form-submit-arrow.svg'
import chevron from '../images/icons/chevron.svg'

const Blog = ({ location, data }) => {
  const blog = data.contentfulBlogPost
  const relatedBlogs = data.allContentfulBlogPost.nodes
  console.log('blog.content', blog.content)

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const uri = node.data.uri;

        if (uri.includes('youtube.com')) {
          return (
            <iframe
              width="560"
              height="315"
              src={uri}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          );
        }

        const isExternal = uri.startsWith('https');
        if (isExternal) {
          return (
            <a href={uri} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
              {children}
            </a>
          );
        } else {
          return <a href={uri}>{children}</a>;
        }
      },

      [BLOCKS.HEADING_2]: (node, children) => (
        <BlogSubhead>{children}</BlogSubhead>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => <BlogCopy>{children}</BlogCopy>,
      [BLOCKS.HEADING_4]: (node, children) => (
        <BlogImageCaption>{children}</BlogImageCaption>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const contentfulID = node.data.target.sys.id
        const imageRef = blog.content?.references.find(
          (ref) => ref.contentful_id === contentfulID
        )
        return (
          <BlogImage
            image={imageRef.gatsbyImageData}
            alt={imageRef.filename}
            tw="w-full"
          />
        )
      },

    },
  }

  return (
    <Layout location={location}>
      <Seo title={blog.title} />
      {/* Hero */}
      <section tw="bg-[#F1FAFD]">
        <div tw="mx-7 py-8 lg:(grid max-w-screen-lg grid-cols-12 gap-x-6 mx-auto h-[511px] pt-0 pb-9 box-border)">
          {/* Left side */}
          <div tw="lg:(col-span-6 flex flex-col justify-end)">
            {/* Bcrumbs */}
            <div css={[ButtonStyle, tw`mb-8`]}>
              <a href="/blog">
                <span>Blog</span>
              </a>
              <span tw="mx-1.5">{`>`}</span>
              <span tw="text-[#FF4D00]">{blog.title}</span>
            </div>
            {/* Image */}
            <GatsbyImage
              image={blog.image.gatsbyImageData}
              alt={blog.image.filename}
              tw="mb-5 lg:(mb-0 w-full h-[376px] object-cover)"
            />
          </div>
          {/* Right side */}
          <div tw="lg:(col-span-5 flex flex-col justify-end mb-16)">
            <h1 css={[HeaderLGStyle, tw`mb-9 lg:(mb-7)`]}>{blog.title}</h1>
            <div tw="mb-5 lg:(mb-9)">
              <span
                css={[SubheadSMStyle, tw`block mb-1.5 lg:(inline mb-0 mr-4)`]}
              >
                {blog.authorName}
              </span>
              <span css={[CopyStyle]}>{blog.date}</span>
            </div>
            <div>
              {blog.tags.map((tag, index) => (
                <span
                  css={[ButtonStyle, tw`bg-[#C2E1EA] px-3 py-1.5 mr-7`]}
                  key={`tag-${index}`}
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Content */}
      <section>
        <div tw="mx-7 py-5 lg:(grid max-w-screen-lg grid-cols-12 gap-x-6 mx-auto pt-14 pb-36)">
          {/* Content */}
          <div tw="lg:(col-span-7)">
            {documentToReactComponents(JSON.parse(blog.content.raw), options)}
          </div>

          {/* Related blogs */}
          {relatedBlogs.length ? (
            <div tw="lg:(col-start-9 col-span-4)">
              <h2 css={[SubheadStyle, tw`mb-5 lg:(mb-6)`]}>Related Blogs</h2>
              {relatedBlogs.map((relatedBlog, index) => (
                <Disclosure
                  as="div"
                  key={`related-blog-${index}`}
                  tw="border border-[#202C3A] mb-4"
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button tw="py-7 px-8 w-full text-left">
                        <div tw="mb-2 flex justify-between items-center">
                          <h3 css={[SubheadSMStyle]}>{relatedBlog.title}</h3>
                          <img
                            src={chevron}
                            css={[
                              open &&
                              css`
                                  transform: rotate(180deg);
                                `,
                            ]}
                          />
                        </div>
                        <div css={[CopySMStyle]}>
                          <span>{relatedBlog.authorName}</span>
                          <span tw="mx-1.5">{'|'}</span>
                          <span>{relatedBlog.date}</span>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel tw="pb-7 px-8">
                        <p css={[CopyStyle, tw`mb-2`]}>
                          {relatedBlog.description}
                        </p>
                        <LinkButton
                          destination={`/blog/${slugify(
                            relatedBlog.title
                          ).toLowerCase()}`}
                        >
                          Read<img src={arrow} tw="w-8 ml-3 inline-block" />
                        </LinkButton>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>

      {/* CTA */}
      <CallToAction
        header="Let's improve patient care together."
        copy="Choose the day, time, skill, and rate you want and get matched with
      qualified talent immediately."
        ctas={[
          { copy: 'Start Earning', destination: '/start-earning' },
          { copy: 'Find Talent', destination: '/find-talent' },
        ]}
      />
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query ($id: String!, $tags: [String]!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      date
      authorName
      description
      tags
      image {
        filename
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1024
          quality: 80
        )
      }
      content {
        raw
        references {
          contentful_id
          filename
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: NONE
            width: 1024
            quality: 80
          )
        }
      }
    }

    allContentfulBlogPost(
      filter: { tags: { in: $tags }, id: { ne: $id } }
      limit: 4
    ) {
      nodes {
        title
        date
        authorName
        description
      }
    }
  }
`
